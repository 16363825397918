<template>
    <div v-for="publish in PlayGame" :key="publish.id">
        <iframe :src="publish.gameUrl" style="max-height: calc(100vh);" width="100%" height="900" allowfullscreen frameborder="0" scrolling="no">
            <p>
                <a href="/en-US/docs/Glossary">
                   Fallback link for browsers that don't support iframes
                </a>
            </p>
        </iframe>
    </div>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { ref } from "vue";
export default {
    name: 'Plays',
    props: {
       id: String,
    },
    async setup(props) {
       const PlayGame = ref([]);
          try {
          await HTTP.get("readPost.php?id=" + props.id).then((res) => {
               PlayGame.value = res.data.readPost;
               });
        } catch (err) {
            console.log(err)
        }
    return {
        PlayGame
    }
   }
}
</script>

<style>
iframe { border: none }
</style>